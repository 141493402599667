import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
    UntypedFormControl
} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {Airport, TripLeg} from '@nxt/model-laro';
import {AirportInputComponent} from './airport-input.component';

import {parse, format} from 'date-fns';
import {InputStackedTextComponent} from '@library/shared/input/input-stacked-text.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        AirportInputComponent, InputStackedTextComponent
    ],
    selector: 'leg-form',
    template: `
        <form [formGroup]="form" *ngIf="form">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">

                <airport-input-component
                        #departureAirport
                        controlName="departureAirport"
                        [form]="form"
                        (airportSelected)="setAirport('departureAirport',$event)"
                        label="FROM"
                ></airport-input-component>

                <airport-input-component
                        #arrivalAirport
                        controlName="arrivalAirport"
                        [form]="form"
                        (airportSelected)="setAirport('arrivalAirport',$event)"
                        label="TO"
                ></airport-input-component>
                
                <input-stacked-text
                        [form]="form"
                        *ngIf="includeDate"
                        (onChange)="setTimeProp('takeoffDate',$event)"
                        controlName="takeoffDate"
                        inputType="date"
                        [label]="requireDate ? 'Date' : 'Date (optional)'">
                </input-stacked-text>

                <input-stacked-text
                        *ngIf="includeTime"
                        [form]="form"
                        (onChange)="setTimeProp('takeoffTime',$event)"
                        controlName="takeoffTime"
                        inputType="time"
                        [label]="requireTime ? 'Time' : 'Time (optional)'">
                </input-stacked-text>

            </div>

        </form>
    `
})
export class LegFormComponent implements OnChanges {
    @Output() onChange: EventEmitter<TripLeg> = new EventEmitter<TripLeg>();
    @Output() onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() form: UntypedFormGroup;
    @Input() includeTime: boolean = false;
    @Input() requireTime: boolean = false;
    @Input() includeDate: boolean = false;
    @Input() requireDate: boolean = false;
    @Input() leg: TripLeg;
    @ViewChild('departureAirport') departureAirport: AirportInputComponent;
    @ViewChild('arrivalAirport') arrivalAirport: AirportInputComponent;

    constructor(private fb: UntypedFormBuilder) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.leg) {
            this.form = this.fb.group({
                departureAirport: [this.leg.departureAirport || new Airport(), [Validators.required]],
                arrivalAirport: [this.leg.arrivalAirport || new Airport(), [Validators.required]],
                takeoffDate: [this.leg.times.takeoffDate || '', this.requireDate ? [Validators.required,this.validDate] : null],
                takeoffTime: [this.leg.times.takeoffTime || '', this.requireTime ? Validators.required : null]
            });
        }
        this.onLoaded.emit(true);
    }

    public validDate(control: UntypedFormControl) {
        let d: Date = parse(control.value,  'yyyy-MM-dd', new Date());
        if (d && d.valueOf() > Date.now()) {
            return true;
        } else {
            return {'required': true};
        }
    }

    // Disallow same airport for takeoff and landing
    // ValidateAirports(control: AbstractControl) {
    //     if (!control.value) {
    //         return {required: true};
    //     }
    //     return null;
    // }


    setAirport(prop: string, airport: Airport) {
        this.leg[prop] = airport;
        this.onChange.emit(this.leg);
    }

    setTimeProp(prop: string, value: any) {
        this.leg.times[prop] = value;
        this.onChange.emit(this.leg);
    }

    save() {
        try {
            let d: Date;
            if (this.includeDate && this.form.get('takeoffDate')?.value) {
                this.leg.times.takeoffDate = this.form.get('takeoffDate').value;
                d = parse(`${this.leg.times.takeoffDate}`, 'yyyy-MM-dd', new Date());
            }
            if (this.includeTime && this.form.get('takeoffTime')?.value) {
                this.leg.times.takeoffTime = this.form.get('takeoffTime').value;
                d = new Date(`${this.leg.times.takeoffDate} ${this.leg.times.takeoffTime}`);
                this.leg.times.takeoffTime = format(d, 'h:mm a');
            } else if (!this.includeDate || !this.form.get('takeoffTime')?.value) {
                // Set default takeoff time of 10AM SO THAT our marketplace searching
                // doesn't get cross up on dates that are set to midnight and match on the previous day
                // due to timezone issues.
                d = new Date(`${this.leg.times.takeoffDate} 10:00 AM`);
                this.leg.times.takeoffTime = format(d, 'h:mm a');
            }
            this.leg.times.takeoffUTC = d?.valueOf() || Date.now();
        } catch (e) {
            console.warn(e);
        }
        this.onChange.emit(this.leg);
        return this.leg;
    }

}
