import {Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule, Validators, UntypedFormControl} from '@angular/forms';

import {AirportInputComponent} from './airport-input.component';
import {LegFormComponent} from './leg-form.component';

import {IMenuItem} from '@nxt/model-core';
import {
    QuoteRequest,
    ETripType,
    TripLeg,
    Airport,
    EPaxType,
    Contact as LaroContact,
    Aircraft
} from '@nxt/model-laro';
import {format} from 'date-fns';
import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {InputSelectComponent} from '@library/shared/input/input-select.component';
import {InputStackedTextAreaComponent} from '@library/shared/input/input-stacked-textarea.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        AirportInputComponent, LegFormComponent,
        InputSelectComponent, InputStackedTextAreaComponent
    ],
    selector: 'quote-trip-form',
    template: `
        <form [formGroup]="form" autocomplete="off">

            <button *ngFor="let type of tripTypes"
                    (click)="updateLegs(type.value)"
                    [class]="quote.tripType===type.value ? 'btn-light btn-sm mr-0 whitespace-nowrap rounded-none' : 'btn-gray btn-sm mr-0 whitespace-nowrap rounded-none'">
                {{type.label}}
            </button>

            <div class="mt-2 w-3/12">
                <input-select
                        [form]="form"
                        [options]="paxNum"
                        label="PASSENGERS"
                        controlName="paxNum">
                </input-select>
            </div>

            <ng-container *ngFor="let leg of quote.legs; let i = index;">
                <div class="relative mt-5">
                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                        <div class="w-full border-t border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-start">
                        <span class="pr-2 bg-white text-sm text-gray-500"> Leg {{i + 1}} </span>
                    </div>
                </div>
                <leg-form [leg]="leg" [includeTime]="includeTime" [requireTime]="requireTime"
                          [includeDate]="includeDate" [requireDate]="requireDate"
                          (onChange)="updateLeg(i,$event)"></leg-form>
            </ng-container>

            <div class="flex mt-4" *ngIf="quote.tripType === ETripType.MULTI_LEG">
                <button class="btn-light btn-sm mr-4" (click)="addLeg()">Add Leg</button>
                <button class="btn-light btn-sm" (click)="removeLeg()">Remove Leg</button>
            </div>
            <div class="mt-4 max-w-500 m-auto" *ngIf="isTripForm">
                <input-stacked-textarea
                        controlName="notes"
                        minHeight="25vh"
                        [form]="form"
                        label="Trip Notes/Requests">
                </input-stacked-textarea>
            </div>
        </form>

        <div class="w-full flex content-center max-w-500 m-auto p-4">
            <button class="flex-none btn-gray" (click)="clear();">
                <span class="w-full tex-center">Clear</span>
            </button>
            <button class="grow btn-accent" (click)="getQuote()">
                <span class="w-full tex-center" *ngIf="!isTripForm">Get Quote</span>
                <span class="w-full tex-center" *ngIf="isTripForm">Request Trip</span>
            </button>
        </div>
    `
})
export class QuoteTripFormComponent implements OnChanges {
    @ViewChildren(LegFormComponent) legForms: QueryList<LegFormComponent>;
    @Output() onResults: EventEmitter<[QuoteRequest[], boolean]> = new EventEmitter<[QuoteRequest[], boolean]>();
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Input() includeTime: boolean = false;
    @Input() requireTime: boolean = false;
    @Input() includeDate: boolean = false;
    @Input() requireDate: boolean = false;
    @Input() isTripForm: boolean;
    @Input() params: any;
    loaded: boolean[] = [];
    quote: QuoteRequest = new QuoteRequest();
    form: UntypedFormGroup;
    paxNum: IMenuItem[] = [
        {value: 1, label: '1'}, {value: 2, label: '2'},
        {value: 3, label: '3'}, {value: 4, label: '4'},
        {value: 5, label: '5'}, {value: 6, label: '6'},
        {value: 7, label: '7'}, {value: 8, label: '8'}
    ]
    tripTypes: IMenuItem[] = [
        {
            value: ETripType.ROUND_TRIP,
            label: 'Round Trip',
        },
        {
            value: ETripType.ONE_WAY,
            label: 'One Way',
        },
        {
            value: ETripType.MULTI_LEG,
            label: 'Multi City',
        },
    ];
    ETripType = ETripType;

    constructor(
        private fb: UntypedFormBuilder,
        private pSvc: PageService,
        private cSvc: ClientService
    ) {

    }

    clear() {
        this.quote = null;
        this.ngOnChanges();
        this.onClear.emit();
    }

    ngOnChanges(changes?: SimpleChanges) {
        if (!this.quote) {
            this.quote = new QuoteRequest();
        }
        this.quote.tripType = this.params?.type ? this.params.type : this.quote.tripType || ETripType.ROUND_TRIP;
        this.form = this.fb.group({
            paxNum: [this.params?.pax !== undefined ? Number(this.params.pax) : this.quote.paxNum ? this.quote.paxNum : 2, [Validators.required]]
        });
        if (this.isTripForm) {
            this.form.addControl('notes', new UntypedFormControl(''));
        }
        this.buildLegs();
    }

    buildLegs() {
        let legs: TripLeg[];
        switch (this.quote.tripType) {
            case ETripType.ONE_WAY:
                legs = this.quote.legs?.length ? [this.quote.legs[0]] : [new TripLeg()];
                break;
            case ETripType.ROUND_TRIP:
                legs = this.quote.legs?.length >= 2 ? [this.quote.legs[0], this.quote.legs[1]] : [new TripLeg(), new TripLeg()];
                break;
            case ETripType.MULTI_LEG:
                legs = this.quote.legs?.length ? this.quote.legs : [];
                while (legs.length < 3) {
                    legs.push(new TripLeg());
                }
                break;
        }

        if (this.params?.legs) {
            try {

                let parts: string[] = this.params.legs.split('|');
                parts.forEach((part, n) => {
                    let subparts: string[] = part.split(',');
                    legs[n] = legs[n] || new TripLeg();
                    legs[n].departureAirport = new Airport({icao: subparts[0]});
                    legs[n].arrivalAirport = new Airport({icao: subparts[1]});
                    let d: Date = new Date(Number(subparts[2]));
                    legs[n].times.takeoffDate = format(d, 'M/d/yyyy');
                    if (subparts[3]) {
                        legs[n].times.takeoffTime = format(d, 'HH:mm');
                    }
                    if (subparts[4]) {
                        legs[n].times.takeoffTZ = subparts[4];
                    }

                    if (this.params.aircraft) {
                        legs[n].aircraft = new Aircraft({tail_number: this.params.aircraft});
                    }
                });
            } catch (e) {
                console.warn(e);
            }
        }

        this.quote.legs = legs.map(l => {
            if (this.form.get('paxNum').value) {
                this.quote.paxNum = Number(this.form.get('paxNum').value);
                l.passengers = [];
                while (l.passengers.length < this.quote.paxNum) {
                    l.passengers.push(new LaroContact({pax_type: EPaxType.ADULT}));
                }
            }
            return l;
        });

        if (this.params?.legs) {
            this.getQuoteAuto(true);
        }
    }

    updateLegs(type: ETripType) {
        this.quote.tripType = type;
        this.buildLegs();
    }

    updateLeg(n, e) {
        this.quote.legs[n] = e;
        switch (this.quote.tripType) {
            case ETripType.ROUND_TRIP:
                if (!n) {
                    this.legForms.forEach((legForm: LegFormComponent, x: number) => {
                        if (x) {
                            legForm.arrivalAirport.selectAirport({
                                value: '',
                                label: '',
                                airport: this.quote.legs[n].departureAirport as Airport
                            })
                            legForm.departureAirport.selectAirport({
                                value: '',
                                label: '',
                                airport: this.quote.legs[n].arrivalAirport as Airport
                            })
                            // console.log(this.includeDate, legForm.form.get('takeoffDate').value);
                            if (this.includeDate && !legForm.form.get('takeoffDate').value) {
                                legForm.form.get('takeoffDate').setValue(this.quote.legs[n].times.takeoffDate);
                            }
                            // console.log(this.includeTime, legForm.form.get('takeoffTime').value);
                            if (this.includeTime && !legForm.form.get('takeoffTime').value) {
                                legForm.form.get('takeoffTime').setValue(this.quote.legs[n].times.takeoffTime);
                            }
                            console.log(legForm.form.value);
                        }
                    });
                }
                break;
        }
    }

    addLeg() {
        this.quote.legs.push(new TripLeg());
        if (this.quote.legs.length === 2) {
            this.quote.tripType = ETripType.ROUND_TRIP;
        } else if (this.quote.legs.length > 2) {
            this.quote.tripType = ETripType.MULTI_LEG;
        }
    }

    removeLeg() {
        this.quote.legs.pop();
        if (this.quote.legs.length === 2) {
            this.quote.tripType = ETripType.ROUND_TRIP;
        } else if (this.quote.legs.length === 1) {
            this.quote.tripType = ETripType.MULTI_LEG;
        }
    }

    async getQuote() {
        if (this.form.valid) {

            this.quote.paxNum = Number(this.form.get('paxNum').value);
            this.buildLegs();

            let valid: boolean = true;
            this.legForms.forEach((legForm: LegFormComponent, n: number) => {
                if (!legForm.form.valid) {
                    valid = false;
                    legForm.form.markAllAsTouched();
                } else {
                    this.quote.legs[n] = legForm.save();
                    this.quote.legs[n].order = n + 1;
                    if (n && this.quote.legs[n].times.takeoffUTC < this.quote.legs[n - 1].times.takeoffUTC) {
                        valid = false;
                        this.pSvc.alert$.next({
                            title: 'Invalid Date(s)',
                            message: 'Leg dates/times are not valid.'
                        });
                    }
                }
            });

            if (valid) {
                this.getQuoteAuto();
            }

        } else {
            this.form.markAllAsTouched();
        }
    }

    async getQuoteAuto(force?: boolean) {

        try {
            this.pSvc.blocking$.next(true);
            let result: any;
            if (this.isTripForm) {
                result = await this.cSvc.callAPI('/laro/trip/request', 'post', {
                    trip: this.quote.toFullJSON(),
                    notes: this.form.get('notes').value || ''
                });
                this.onResults.emit(result);
            } else {
                result = await this.cSvc.callAPI('/laro/quote/estimate', 'post', {trip: this.quote.toFullJSON()});
                if (result?.length) {
                    this.onResults.emit([result.map(i => new QuoteRequest(i)).sort((a, b) => (a.duration?.flightTime > b.duration?.flightTime) ? 1 : -1), force]);
                }
            }


        } catch (e) {
            this.pSvc.alert$.next(e);
        }
        this.pSvc.blocking$.next(false);
    }


}
