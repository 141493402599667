import {Component, Inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SwUpdate} from '@angular/service-worker';
import {DomSanitizer} from '@angular/platform-browser';

import {AccountService} from '@library/nxt/_services/account.service';
import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {ThemeService} from '@library/shared/_services/theme.service';
import {MessagingService} from '@library/nxt/_services/messaging.service';
import {UserService} from '@library/nxt/_services/user.service';
import {RootNxtAppComponent} from '@library/shared/_inherited/root-nxt-app.component';

@Component({
    selector: 'app-root',
    template: `
        <nxt-tos-header></nxt-tos-header>
        <notification-dialog></notification-dialog>
        <progress-component [loading$]="pSvc.loading$" [blocking$]="pSvc.blocking$"></progress-component>
        <!-- CMS-POWERED CONTENT -->
        <ng-container *ngIf="(pSvc.page$|async)?.id">
            <flex-page-component [page]="(pSvc.page$|async)?.header?.id"></flex-page-component>
            <router-outlet></router-outlet>
            <flex-page-component [page]="(pSvc.page$|async)?.footer?.id"></flex-page-component>
        </ng-container>
        <!-- LEVO-ADMIN CONTENT -->
        <ng-container *ngIf="(pSvc.page$|async) && !(pSvc.page$|async).id">
            <nxto-header-component (menuToggleClicked)="sidebarComponent.toggle()"></nxto-header-component>
            <div style="padding-top: 64px;">
                <on-boarding></on-boarding>
                <div class="flex w-full h-screen overflow-hidden">
                    <div class="flex-none">
                        <sidebar-component (onClick)="mSvc.flushThread('sideebar')" [menu]="pSvc.menu$|async"
                                           #sidebarComponent></sidebar-component>
                    </div>
                    <div [class]="contentClass">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container #modalContainer></ng-container>
    `
})
export class OperatorAppComponent extends RootNxtAppComponent {

    constructor(
        public cSvc: ClientService,
        public pSvc: PageService,
        public mSvc: MessagingService,
        public uSvc: UserService,
        private aSvc: AccountService,
        @Inject('TemplateLoaderService') private tSvc: any, // Include here so it's available everywhere it might be needed.
        router: Router,
        thSvc: ThemeService,
        swUpdate: SwUpdate,
        sanitizer: DomSanitizer,
        aR: ActivatedRoute
    ) {
        super(cSvc, mSvc, uSvc, pSvc, router, thSvc, swUpdate, sanitizer);

        aR.params.subscribe(p => {
            console.log(p);
        })
    }

}
