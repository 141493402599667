export const laroConsumerLazyComponents: any = {
   'laro-consumer-marketplace': {
      t: 'Levo Consumer Marketplace',
      d: 'Marketplace search component. Consumer-facing.',
      type: 'any',
      loader: () => import('../../_components/consumer-marketplace/laro-consumer-marketplace'),
      config: () => import('../../_components/consumer-marketplace/laro-consumer-marketplace-config')
   },
    'laro-consumer-client-branded-header': {
       t: `Consumer-Facing App Header`,
        d: `Header for consumer-facing site (trips.levo.aero)`,
        type: 'header',
        loader: () => import('../_components/header/laro-consumer-header'),
        config: () => import('../_components/header/laro-consumer-header.config')
    },
    'laro-image-hero': {
        t: `Simple, Image Hero`,
        d: `Display full width image. Originally built for trips.levo.aero homepage to show static image of marketplace map.`,
        loader: () => import('./image-hero/laro-image-hero'),
        config: () => import('./image-hero/laro-image-hero.config')
    },
    'laro-consumer-home-hero': {
        t: `Consumer Home Page Hero`,
        d: `Hero element for trips.levo.aero homepage`,
        loader: () => import('../_components/home-hero/laro-consumer-home-hero'),
        config: () => import('../_components/home-hero/laro-consumer-home-hero.config')
    },
   'laro-features-no-group': {
      t: 'Laro features component with no group toggle',
      d: 'Features component that has no group toggle',
      type: 'any',
      loader: () => import('../_components/features-toggle-no-group/laro-features-no-group'),
      config: () => import('../_components/features-toggle-no-group/laro-features-no-group-config')
   },
   'laro-image-row': {
      t: 'Image Row Component',
      d: 'Images in a row for Laro',
      type: 'any',
      loader: () => import('../_components/image-row/laro-image-row'),
      config: () => import('../_components/image-row/laro-image-row-config')
   },
   'laro-footer': {
      t: 'Newer Footer',
      d: 'Newer Footer component for Laro',
      type: 'any',
      loader: () => import('../_components/laro-footer/laro-footer'),
      config: () => import('../_components/laro-footer/laro-footer-config')
   },
    'laro-empty-legs-hero': {
        t: 'Hero Empty Legs',
        d: 'Empty Legs Hero Component for Laro',
        type: 'any',
        loader: () => import('../_components/empty-legs/empty-legs-header/empty-legs-header'),
        config: () => import('../_components/empty-legs/empty-legs-header/empty-legs-header-config')
    }
}
