"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceChange = exports.Product = exports.ESubscriptionTypes = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
const date_fns_1 = require("date-fns");
var ESubscriptionTypes;
(function (ESubscriptionTypes) {
    ESubscriptionTypes["MONTHLY_SERVICE"] = "monthly";
    ESubscriptionTypes["ANNUALLY_SERVICE"] = "annually";
    ESubscriptionTypes["DAILY_SERVICE"] = "daily";
    ESubscriptionTypes["WEEKLY_SERVICE"] = "weekly";
    ESubscriptionTypes["MONTHLY_PRODUCT"] = "monthly-product";
    ESubscriptionTypes["ANNUALLY_PRODUCT"] = "annually-product";
    ESubscriptionTypes["DAILY_PRODUCT"] = "daily-product";
    ESubscriptionTypes["WEEKLY_PRODUCT"] = "weekly-product";
    ESubscriptionTypes["OTHER"] = "other";
    ESubscriptionTypes["NONE"] = "";
})(ESubscriptionTypes || (exports.ESubscriptionTypes = ESubscriptionTypes = {}));
class Product extends model_core_1.Base {
    constructor(data, olMap, type, idLen) {
        super(type || 'products', idLen || 31);
        this.name = '';
        this.description = '';
        this.notes = '';
        this.sku = '';
        this.weight = null;
        this.weight_ounces = null;
        this.cost = null;
        this.nmfc_class = '';
        this.price = null;
        this.dimensions = new model_core_1.DimensionArray([]);
        this.url = '';
        this.image = '';
        this.address = null;
        this.subscription = false;
        this.stripe_price_id = '';
        this.subscription_type = ESubscriptionTypes.NONE;
        this.metadata = {};
        this.agreement = '';
        this._m = ['weight', 'weight_ounces', 'cost', 'price', 'name', 'sku', 'price', 'dimensions', 'metadata', 'address'];
        (0, index_1.setObjectProperties)(this, data, olMap, Product);
    }
    toString() {
        return `Product: ${this.name}`;
    }
    toJSON() {
        if (this.weight_ounces && !this.weight) {
            this.weight = (0, index_1.round)(this.weight_ounces / 16, 1);
        }
        else if (this.weight && !this.weight_ounces) {
            this.weight_ounces = this.weight_ounces * 16;
        }
        let r = super.toJSON();
        ['weight', 'weight_ounces', 'cost', 'price'].forEach(p => {
            r[p] = (this[p] && !isNaN(this[p])) ? Number(this[p]) : this[p] || null;
        });
        r.address = (0, index_1.toMinJSON)(this, 'address');
        return r;
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        ['weight', 'weight_ounces', 'cost', 'price'].forEach(p => {
            r[p] = (this[p] && !isNaN(this[p])) ? Number(this[p]) : this[p] || null;
        });
        return r;
    }
}
exports.Product = Product;
class PriceChange extends model_core_1.Base {
    constructor(data, olMap) {
        super('changes', null);
        this.cost_based = null;
        this.before = null;
        this.after = null;
        this.percent = null;
        this.amount = null;
        (0, index_1.setObjectProperties)(this, data, olMap, PriceChange);
    }
    toString() {
        return `PriceChange: ${this.before} -> ${this.after}`;
    }
    async save(parent) {
        if (!this.id) {
            this.id = (0, date_fns_1.format)(new Date(), `yyyy-MM-dd'T'HH:mm`);
        }
        return super.save(parent);
    }
}
exports.PriceChange = PriceChange;
index_1.olm.products = (ref, map) => {
    return new Product(ref, map);
};
index_1.olm.changes = (ref, map) => {
    return new PriceChange(ref, map);
};
