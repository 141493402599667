"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LaroCart = exports.LaroQuote = exports.LaroOrder = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class LaroOrder extends model_core_1.Order {
    constructor(data, olMap, type, idLen) {
        super(type || 'orders', idLen || 31);
        this.client = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, LaroOrder);
    }
    validate() {
        var _a, _b, _c, _d, _e, _f, _g;
        if (this.getStageColor('items') !== model_core_1.EOrderStageColors.GREEN) {
            return {
                title: 'Cannot Mark Paid',
                message: 'The Items section must be marked as done.',
                path: 'items',
                color: model_core_1.EOrderStageColors.ORANGE
            };
        }
        else if (!((_a = this.client) === null || _a === void 0 ? void 0 : _a.name_key)) {
            return {
                title: 'Cannot Mark Paid',
                message: 'The Client section must at least have the Name Key property set.',
                path: 'client',
                color: model_core_1.EOrderStageColors.ORANGE
            };
        }
        else if (!((_d = (_c = (_b = this.client) === null || _b === void 0 ? void 0 : _b.config) === null || _c === void 0 ? void 0 : _c.software) === null || _d === void 0 ? void 0 : _d.type)) {
            return {
                title: 'Cannot Mark Paid',
                message: `You must select the client's flight management software option in the Software tab.`,
                path: 'software',
                color: model_core_1.EOrderStageColors.ORANGE
            };
        }
        else if (!((_g = (_f = (_e = this.client) === null || _e === void 0 ? void 0 : _e.config) === null || _f === void 0 ? void 0 : _f.software) === null || _g === void 0 ? void 0 : _g.active)) {
            return {
                title: 'Cannot Mark Paid',
                message: `You must find the complete the customer's flight management software setup.`,
                path: 'software',
                color: model_core_1.EOrderStageColors.ORANGE
            };
        }
    }
    markPaid() {
        let err = this.validate();
        if (err) {
            throw err;
        }
        else {
            super.markPaid();
        }
    }
    toJSON() {
        let r = super.toJSON();
        r.client = (0, model_core_1.toJSON)(this, 'client');
        return r;
    }
}
exports.LaroOrder = LaroOrder;
class LaroQuote extends LaroOrder {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'quotes', 31);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, LaroQuote);
    }
}
exports.LaroQuote = LaroQuote;
class LaroCart extends LaroOrder {
    constructor(data, olMap) {
        super(data, olMap);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, LaroCart);
    }
    async copyToOrder(parent, keep) {
        return new LaroOrder(await super.clean(new LaroOrder(this), parent, keep || ['items', 'shipments'], null, true));
    }
}
exports.LaroCart = LaroCart;
index_1.olm.orders = (ref, map) => {
    return new LaroOrder(ref, map);
};
