import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {ServiceWorkerModule} from '@angular/service-worker';
import {HttpClientModule} from '@angular/common/http';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';

import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {USE_EMULATOR as USE_AUTH_EMULATOR} from '@angular/fire/compat/auth';
import {USE_EMULATOR as USE_FIRESTORE_EMULATOR} from '@angular/fire/compat/firestore';
import {USE_EMULATOR as USE_FUNCTIONS_EMULATOR} from '@angular/fire/compat/functions';

import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFireModule} from '@angular/fire/compat';
import {ScreenTrackingService, UserTrackingService} from '@angular/fire/compat/analytics';

import {environment} from '@env/environment';
import {ClientGuard} from '@library/shared/_services/client.guard';
import {GlobalErrorHandler} from '@library/shared/_services/global-error-handler';
import {NotificationDialog} from '@library/shared/alert/notification.dialog';
import {MessagingService} from '@library/nxt/_services/messaging.service';
import {ClientService} from '@library/shared/_services/client.service';
import {ThemeService} from '@library/shared/_services/theme.service';
import {PageService} from '@library/shared/_services/page.service';
import {UserService} from '@library/nxt/_services/user.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {AccountService} from '@library/nxt/_services/account.service';
import {AuthGuard} from '@library/nxt/_services/auth.guard';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {LaroObjectLoaderService} from '@library/laro/_services/laro.object.loader.service';
import {LaroOperatorMenuService} from '@library/laro/_services/laro.operator.menu.service';
import {LaroAllSearchDialog} from './_components/search/laro-all-search.dialog';
import {LaroTemplateLoaderService} from '@library/laro/_services/laro.template.loader.service';

import {laroOperatorLazyComponents} from './laro-operator.lazy.components';
import {sharedLazyComponents} from '@library/shared.lazy.components';
import {ProgressComponent} from '@library/shared/progress/progress.component';
import {OperatorAppComponent} from './operator.app.component';
import {FlexComponent} from '@library/shared/flex/flex.component';

import {LogoutPage} from '../consumer/account/logout.page';
import {NxtoHeaderComponent} from '@library/nxt/navigation/nxto-header.component';
import {SidebarComponent} from '@library/nxt/navigation/sidebar.component';

import {FeedbackItemsComponent} from '@library/nxt/feedback/feedback-items.component';

import {LaroMarketplaceService} from '@library/laro/_services/laro-marketplace.service';
import {LaroThreadOptionsService} from '@library/laro/_services/laro-thread-options.service';
import {laroConsumerLazyComponents} from "../consumer/_components/laro-consumer.lazy.components";
import {FlexPage} from '@library/shared/flex/flex.page';
import {NxtTosHeader} from '@library/nxt/terms/nxt-tos-header';
import {OnBoarding} from '@library/nxt/on-boarding/on-boarding';

const routes: Routes = [
    {
        path: ':name_key/cms',
        loadChildren: () => import('../../../nxto/src/app/cms/cms.module').then(m => m.CmsModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/orders',
        loadChildren: () => import('../../../nxto/src/app/commerce/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/company',
        loadChildren: () => import('../../../nxto/src/app/company/company.module').then(m => m.CompanyPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/dashboard',
        loadChildren: () => import('../../../nxto/src/app/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/quotes',
        loadChildren: () => import('./quotes/quotes.module').then(m => m.QuotesPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/trips',
        loadChildren: () => import('./trips/trips.module').then(m => m.TripsPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/marketplace',
        loadChildren: () => import('./marketplace/marketplace.module').then(m => m.LaroMarketplaceModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/marketing',
        loadChildren: () => import('./marketing/marketing.module').then(m => m.LaroMarketingPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/activate',
        loadChildren: () => import('../../../nxto/src/app/activate/activate.module').then(m => m.ActivatePagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/threads',
        loadChildren: () => import('../../../nxto/src/app/threads/threads.module').then(m => m.ThreadPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/data/aircraft',
        loadChildren: () => import('./data/aircraft/aircraft.module').then(m => m.AircraftPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/data/airports',
        loadChildren: () => import('./data/airports/airports.module').then(m => m.AirportPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/data/accounts',
        loadChildren: () => import('./data/accounts/accounts.module').then(m => m.AccountsModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/data/pricing',
        loadChildren: () => import('./data/pricing/pricing.module').then(m => m.PricingModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/data',
        loadChildren: () => import('../../../nxto/src/app/data/data.pages.module').then(m => m.DataPagesModule),
        canActivate: [ClientGuard]
    },
    {
        path: ':name_key/tasks',
        loadChildren: () => import('../../../nxto/src/app/tasks/tasks.module').then(m => m.TaskPagesModule),
        canActivate: [ClientGuard],
        data: {isUser: true}
    },
    {
        path: 'login',
        loadChildren: () => import('../../../nxto/src/app/login/login.module').then(m => m.LoginModule),
        canActivate: [ClientGuard]
    },
    {
        path: 'logout',
        component: LogoutPage,
        canActivate: [ClientGuard]
    },
    {
        path: '**',
        component: FlexPage,
        canActivate: [ClientGuard],
        data: {header: true, footer: true}
    }
];
const routerOptions: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled'
};
@NgModule({
    imports: [
        BrowserModule, HttpClientModule,
        RouterModule.forRoot(routes, routerOptions),
        AngularFireModule.initializeApp(environment.firebase, environment.appName),
        AngularFirestoreModule, AngularFireAuthModule, AngularFireStorageModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: true,
            registrationStrategy: 'registerWhenStable:5000'
        }),
        NotificationDialog, FlexComponent, NxtoHeaderComponent, SidebarComponent,
        FeedbackItemsComponent, ProgressComponent, LaroAllSearchDialog, NxtTosHeader, OnBoarding
    ],
    providers: [
        ClientService, ThemeService, PageService,
        MessagingService, FireService,
        LocalStorageService, ClientGuard, AccountService,
        LaroMarketplaceService, UserService,
        {provide: 'UserService', useFactory:  (cSvc: ClientService) => new UserService(cSvc), deps: [ClientService]},
        {
            provide: 'MenuService',
            useFactory: (cSvc: ClientService) => new LaroOperatorMenuService(cSvc),
            deps: [ClientService]
        },
        {provide: 'ObjectLoaderService', useFactory: () => new LaroObjectLoaderService()},
        {provide: 'LazyComponents', useValue: [sharedLazyComponents,laroOperatorLazyComponents, laroConsumerLazyComponents]},
        AuthGuard, ScreenTrackingService, UserTrackingService,
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {
            provide: USE_AUTH_EMULATOR,
            useValue: environment.type === 'local' ? ['http://localhost:9099', 9099] : undefined
        },
        {provide: USE_FIRESTORE_EMULATOR, useValue: environment.type === 'local' ? ['localhost', 8080] : undefined},
        {provide: USE_FUNCTIONS_EMULATOR, useValue: environment.type === 'local' ? ['localhost', 5001] : undefined},
        {
            provide: 'AllSearchDialog',
            useValue: LaroAllSearchDialog
        },
        {
            provide: 'TemplateLoaderService',
            useFactory: (mSvc: MessagingService, cSvc: ClientService, pSvc: PageService) => new LaroTemplateLoaderService(mSvc,cSvc,pSvc),
            deps: [MessagingService, ClientService, PageService]
        },
        {
            provide: 'ThreadOptionsService',
            useFactory: (cSvc: ClientService, uSvc: UserService, fSvc: FireService, pSvc: PageService, AllSearchDialog: 'AllSearchDialog') => new LaroThreadOptionsService(cSvc,uSvc,fSvc,pSvc,AllSearchDialog),
            deps: [ClientService, UserService, FireService, PageService, 'AllSearchDialog']
        }
    ],
    declarations: [OperatorAppComponent],
    bootstrap: [OperatorAppComponent]
})
export class OperatorAppModule {
}
